import React from 'react'
import Button from '@material-ui/core/Button';
import PhoneIcon from '@material-ui/icons/Phone';

const CallUsContainer = (props) => (
    <div className="call-us">

        <a href={"tel:" + props.placePhoneNumNoSpaces} className="call-us-link">

            <Button className="call-us-btn">
                <PhoneIcon />
                &nbsp;
                {props.placePhoneNum}
            </Button>
        </a>
    </div>
)

export default CallUsContainer;