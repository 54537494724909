import axios from 'axios';
import { RESERVATIONS_API_URL, API_TOKEN } from '../constants';
import { LIST_PLACES, LIST_PLACES_ERROR, LIST_ROOMS, LIST_ROOMS_ERROR, RESERVATION_CREATED, RESERVATION_ERROR, RESERVATION_CENCELED, RESERVATION_CENCELED_ERROR, LIST_SETTINGS, LIST_SETTINGS_ERROR, FREE_TABLES_FOR_TIME, CLEAR_RESERVATION_ERROR, GET_BUSY_DATES, GET_BUSY_DATES_ERROR, GET_ALLOWED_PEOPLE_FOR_TIME, GET_ALLOWED_PEOPLE_FOR_TIME_ERROR, SWITCH_LANGUAGE } from './types';

const customErrorMsg = "Възникна грешка. Моля опитайте по-късно или се свържете се с нас! #";

const errorHandler = (error) => {
    let errorCode = 0;

    // https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
    if (error.response) {
        errorCode = 1;
        console.log(error.response.data); //DO NOT REMOVE
        console.log(error.response.status); //DO NOT REMOVE
        console.log(error.response.headers); //DO NOT REMOVE
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
        */
    } else if (error.request) {
        errorCode = 2;
        /*
        * The request was made but no response was received, `error.request`
        * is an instance of XMLHttpRequest in the browser and an instance
        * of http.ClientRequest in Node.js
        */
        console.log(error.request); //DO NOT REMOVE
    } else {
        errorCode = 3;
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message); //DO NOT REMOVE
    }

    return customErrorMsg + errorCode;
}

export const listPlaces = () => dispatch => {
    axios
        .get(RESERVATIONS_API_URL + 'place/read')
        .then(res => {
            dispatch({
                type: LIST_PLACES,
                payload: res.data
            })
        }
        ).catch(error =>
            dispatch({
                type: LIST_PLACES_ERROR,
                payload: (error.response && error.response.data) ? error.response.data : { message: errorHandler(error) }
            })
        );
}

export const getBusyDates = (data) => dispatch => {
    axios
        .post(RESERVATIONS_API_URL + 'reservation/getBusyDates', data)
        .then(res => {
            dispatch({
                type: GET_BUSY_DATES,
                payload: res.data
            })
        }
        ).catch(error =>
            dispatch({
                type: GET_BUSY_DATES_ERROR,
                payload: (error.response && error.response.data) ? error.response.data : { message: errorHandler(error) }
            })
        );
}

export const listRooms = (getRoomsQuery = '') => dispatch => {
    axios
        .get(RESERVATIONS_API_URL + 'room/read' + getRoomsQuery)
        .then(res => {
            dispatch({
                type: LIST_ROOMS,
                payload: res.data
            })
        }
        ).catch(error =>
            dispatch({
                type: LIST_ROOMS_ERROR,
                payload: (error.response && error.response.data) ? error.response.data : { message: errorHandler(error) }
            })
        );
}

export const makeReservation = (data) => dispatch => {
    let reservationErrorText = document.querySelector('.reservation-error-text');
    if (reservationErrorText) {
        reservationErrorText.classList.add('dnone');
    }

    let loadingIcon = document.querySelector('.reservation-loading-icon');
    if (loadingIcon) {
        loadingIcon.classList.remove('dnone');
    }

    axios
        .post(RESERVATIONS_API_URL + 'reservation/create', data)
        .then(res => {
            dispatch({
                type: RESERVATION_CREATED,
                payload: res.data
            });
            if (reservationErrorText) {
                reservationErrorText.classList.remove('dnone');
            }
            if (loadingIcon) {
                loadingIcon.classList.add('dnone');
            }

        }
        ).catch(error => {
            dispatch({
                type: RESERVATION_ERROR,
                payload: (error.response && error.response.data) ? error.response.data : { message: errorHandler(error) }
            });
            if (reservationErrorText) {
                reservationErrorText.classList.remove('dnone');
            }
            if (loadingIcon) {
                loadingIcon.classList.add('dnone');
            }

        });
}

export const cancelReservation = (data) => dispatch => {
    axios
        .post(RESERVATIONS_API_URL + 'reservation/update', data)
        .then(res => {
            dispatch({
                type: RESERVATION_CENCELED,
                payload: res.data
            })
        }
        ).catch(error =>
            dispatch({
                type: RESERVATION_CENCELED_ERROR,
                payload: (error.response && error.response.data) ? error.response.data : { message: errorHandler(error) }
            })
        );
}

export const getReservationsSettings = () => dispatch => {
    axios
        .get(RESERVATIONS_API_URL + 'settings/read')
        .then(res => {
            dispatch({
                type: LIST_SETTINGS,
                payload: res.data
            })
        }
        ).catch(error =>
            dispatch({
                type: LIST_SETTINGS_ERROR,
                payload: (error.response && error.response.data) ? error.response.data : { message: errorHandler(error) }
            })
        );
}

export const checkIfFreeTablesForTime = (data) => dispatch => {
    axios
        .post(RESERVATIONS_API_URL + 'reservation/checkIfFreeTablesForTime', data)
        .then(res => {
            dispatch({
                type: FREE_TABLES_FOR_TIME,
                payload: res.data.status
            })
        }
        ).catch(error => {
            dispatch({
                type: FREE_TABLES_FOR_TIME,
                payload: true
            })
        }
        );
}


export const getAllowedCountPeople = (getAllowedCountPeopleQuery = '') => dispatch => {
    axios
        .get(RESERVATIONS_API_URL + 'reservation/getAllowedPeopleForTime' + getAllowedCountPeopleQuery)
        .then(res => {
            dispatch({
                type: GET_ALLOWED_PEOPLE_FOR_TIME,
                payload: res.data
            })
        }
        ).catch(error =>
            dispatch({
                type: GET_ALLOWED_PEOPLE_FOR_TIME_ERROR,
                payload: (error.response && error.response.data) ? error.response.data : { message: errorHandler(error) }
            })
        );
}



export const clearResMessages = () => dispatch => {
    dispatch({
        type: CLEAR_RESERVATION_ERROR,
        payload: true
    });
}

export const setFreeTablesForTime = () => dispatch => {
    dispatch({
        type: FREE_TABLES_FOR_TIME,
        payload: true
    });
}


export const switchLanguage = (lang) => dispatch => {
    dispatch({
        type: SWITCH_LANGUAGE,
        payload: lang
    })
}