import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CallUsContainer from './CallUsContainer';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		// width: 200,
	},
	dense: {
		marginTop: 19,
	},
	menu: {
		width: 200,
	},
}));

const CustomColoredCheckbox = withStyles({
	// root: {
	//   color: '#c9b040',
	//   '&$checked': {
	//     color: '#b89932',
	//   },
	// },
	checked: {},
})(props => <Checkbox {...props} />);

export default function ConfirmationForm(props) {
	let termsSelector = document.querySelector('.checkboxes label.terms-checkbox span');
	let privacyPolicySelector = document.querySelector('.checkboxes label.privacy-policy-checkbox span');
	let nameSelector = document.querySelector('.input-fields div.MuiFormControl-root:first-child .MuiInputBase-input');
	let phoneNumberSelector = document.querySelector('.input-fields div.MuiFormControl-root:last-child .MuiInputBase-input');

	const classes = useStyles();
	const [values, setValues] = React.useState({
		name: props.reservationState && props.reservationState.reservationPersonName ? props.reservationState.reservationPersonName : (localStorage.getItem('reservation_name') ? localStorage.getItem('reservation_name') : ''),
		number: props.reservationState && props.reservationState.reservationPersonPhone ? props.reservationState.reservationPersonPhone : (localStorage.getItem('reservation_phone') ? localStorage.getItem('reservation_phone') : ''),
	});

	const [state, setState] = React.useState({
		checkedPrivacyPolicy: false,
		checkedTerms: false,
		checkedSaveToStorage: (localStorage.getItem('reservation_name') && localStorage.getItem('reservation_phone')) ? true : false
	});

	let [errors, setErrors] = React.useState({
		reservation: props.reservation_error && props.reservation_error.message && Object.values(props.reservation_error.message).length > 0 ? props.reservation_error.message[props.curr_lang] : undefined,
		no_free_tables: props.reservation_error && props.reservation_error.no_free_tables ? props.reservation_error.no_free_tables : false,
	})


	if (typeof errors.reservation === "undefined" && Object.keys(props.reservation_error).length > 0) {
		setErrors({ reservation: props.reservation_error && props.reservation_error.message && Object.values(props.reservation_error.message).length > 0 ? props.reservation_error.message[props.curr_lang] : undefined });
	}

	if (typeof errors.reservation === "object" && Object.keys(errors.reservation).length === 0) {
		setErrors({ reservation: undefined });
	}

	if ((values.name.length >= 4) && (nameSelector)) {
		nameSelector.style.borderColor = '#c9b040';
	}

	if ((values.number.length >= 4) && (phoneNumberSelector)) {
		phoneNumberSelector.style.borderColor = '#c9b040';
	}

	// if (state.checkedTerms === true) {
	//   termsSelector.style.borderColor = '#c9b040';
	// }
	// if (state.checkedPrivacyPolicy === true) {
	//   privacyPolicySelector.style.borderColor = '#c9b040';
	// }   

	// console.log('state.checkedPrivacyPolicy', state.checkedPrivacyPolicy.checked)

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};

	const handleChangeCheckbox = name => event => {
		setState({ ...state, [name]: event.target.checked });

		if ((name === 'checkedPrivacyPolicy') && (privacyPolicySelector)) {
			privacyPolicySelector.style.color = event.target.checked ? '#c9b040' : 'red';
		}

		if ((name === 'checkedTerms') && (termsSelector)) {
			termsSelector.style.color = event.target.checked ? '#c9b040' : 'red';
		}
	};

	const handleReservation = (data) => {
		let resError = undefined;
		if (props.reservation_error && props.reservation_error.message && Object.values(props.reservation_error.message).length > 0) {
			resError = props.reservation_error.message[props.curr_lang];
		} else if (props.reservation_id && props.reservation_id === 0) {
			resError = props.translations.confirmation.booking_unsuccessful;
			//'Резервацията не беше въведена в системата ни, опитайте по-късно или се свържете с нас!';
		}

		setErrors({ reservation: resError });

		let reservationError;

		if (termsSelector) {
			termsSelector.style.color = '#c9b040';
		}
		if (privacyPolicySelector) {
			privacyPolicySelector.style.color = '#c9b040';
		}
		if (nameSelector) {
			nameSelector.style.borderColor = '#9e9e9e';
		}
		if (phoneNumberSelector) {
			phoneNumberSelector.style.borderColor = '#9e9e9e';
		}

		if (data.terms === false) {
			reservationError = true;
			if (termsSelector) {
				termsSelector.style.color = 'red';
			}
			setErrors({ reservation: props.translations.confirmation.please_enter_your_details_correctly });
			// 'Моля въведете вашите данни коректно и се уверете че сте се съгласили с условията'
		}
		if (data.privacyPolicy === false) {
			reservationError = true;
			if (privacyPolicySelector) {
				privacyPolicySelector.style.color = 'red';
			}
			setErrors({ reservation: props.translations.confirmation.please_enter_your_details_correctly });
			// 'Моля въведете вашите данни коректно и се уверете че сте се съгласили с условията'
		}

		if (data.name.length < 4) {
			reservationError = true;
			if (nameSelector) {
				nameSelector.style.borderColor = 'red';
			}
			setErrors({ reservation: props.translations.confirmation.please_enter_your_details_correctly });
			// 'Моля въведете вашите данни коректно и се уверете че сте се съгласили с условията'
		}

		if (data.number.length < 4) {
			reservationError = true;
			if (phoneNumberSelector) {
				phoneNumberSelector.style.borderColor = 'red';
			}
			setErrors({ reservation: props.translations.confirmation.please_enter_your_details_correctly });
			// 'Моля въведете вашите данни коректно и се уверете че сте се съгласили с условията'
		}


		if (!reservationError /*&& !props.reservation_error && !props.reservation_error.message*/) {
			if (data.saveToStorage) {
				localStorage.setItem('reservation_name', data.name)
				localStorage.setItem('reservation_phone', data.number)
			} else {
				localStorage.removeItem('reservation_name');
				localStorage.removeItem('reservation_phone');
			}
			props.handleReservation(data);
		}
	}


	// console.log('values.name', values.name)

	const [open, setOpen] = React.useState(false);
	const [scroll, setScroll] = React.useState('paper');

	const handleClickOpen = scrollType => () => {
		setOpen(true);
		setScroll(scrollType);
	};

	const handleClose = () => {
		setOpen(false);
	};


	const [openSecond, setOpenSecond] = React.useState(false);
	const [scrollSecond, setScrollSecond] = React.useState('paper');

	const handleClickOpenSecond = scrollType => () => {
		setOpenSecond(true);
		setScrollSecond(scrollType);
	};

	const handleCloseSecond = () => {
		setOpenSecond(false);
	};


	const placePhoneNum = (props.places && props.reservationState.reservationPlace && props.places[props.reservationState.reservationPlace] && props.places[props.reservationState.reservationPlace].contact_phone) ? props.places[props.reservationState.reservationPlace].contact_phone : '';
	const placePhoneNumNoSpaces = placePhoneNum.replace(' ', '')

	return (
		<React.Fragment>
			<form className={classes.container} noValidate autoComplete="off">

				<div className="confirmation-holder">

					<div className="confirmation-holder-form">

						<div className="reservation-error">
							<img src='https://tabl.bg/img/reservation-system/reservation-loading.gif' className="dnone reservation-loading-icon" style={{ height: 100 }} alt="loading" />
							{errors.reservation ?
								<div className="reservation-error-text">
									{errors.reservation}
									<br />
									{errors.no_free_tables === false ?
										<CallUsContainer
											placePhoneNum={placePhoneNum}
											placePhoneNumNoSpaces={placePhoneNumNoSpaces}
										/>
										:
										<div className="mbottom15"></div>
									}
									<hr />
								</div>
								:
								''
							}
						</div>

						<div className="input-fields">
							<TextField
								id="standard-name"
								// label="Име и Фамилия"
								label={props.translations.confirmation.first_and_last_name}
								className={classes.textField}
								value={values.name}
								onChange={handleChange('name')}
								margin="normal"
							/>

							<TextField
								id="standard-number"
								// label="Тел. номер"
								label={props.translations.confirmation.phone}
								value={values.number}
								onChange={handleChange('number')}
								type="number"
								className={classes.textField}
								// InputLabelProps={{
								//   shrink: true,
								// }}
								margin="normal"
							/>
						</div>

						<div className="checkboxes">
							<FormControlLabel
								className="privacy-policy-checkbox"
								control={
									<CustomColoredCheckbox
										checked={state.checkedPrivacyPolicy}
										onChange={handleChangeCheckbox('checkedPrivacyPolicy')}
									/>
								}
								label={<React.Fragment>{props.translations.confirmation.read_and_agree_with} <Button onClick={handleClickOpenSecond('paper')} className="underlined">{props.translations.confirmation.the_privacy_policies}</Button>
									{/* Запознах се и съм съгласен с */}
									{/* Политиките за поверителност */}
								</React.Fragment>}
							/>

							<FormControlLabel
								className="terms-checkbox"
								control={
									<CustomColoredCheckbox
										checked={state.checkedTerms}
										onChange={handleChangeCheckbox('checkedTerms')}
									/>
								}
								label={<React.Fragment>
									{props.translations.confirmation.agree_with} <Button onClick={handleClickOpen('paper')} className="underlined">{props.translations.confirmation.the_terms_of_use}</Button> {props.translations.confirmation.have_16_years}
									{/* // Съгласен съм с */}
									{/* Условията за ползване */}
									{/* и имам навършени 16 години */}
								</React.Fragment>}
							/>

							<FormControlLabel
								className="save-to-storage-checkbox"
								control={
									<CustomColoredCheckbox
										checked={state.checkedSaveToStorage}
										onChange={handleChangeCheckbox('checkedSaveToStorage')}
									/>
								}
								label={<React.Fragment>
									{props.translations.confirmation.remember_my_credentials}
									{/* Запомни данните ми при следващо влизане */}
								</React.Fragment>}
							/>
						</div>
					</div>
					<div>

						{/* <br/> */}

						<Button
							className="book mtop15"
							variant="contained"
							onClick={(e) => handleReservation({ name: values.name, number: values.number, terms: state.checkedTerms, privacyPolicy: state.checkedPrivacyPolicy, saveToStorage: state.checkedSaveToStorage })}
						>
							{props.translations.confirmation.make_a_reservation}
							{/* Резервирай */}
						</Button>


						<Dialog
							open={open}
							onClose={handleClose}
							scroll={scroll}
							aria-labelledby="scroll-dialog-title"
						>
							<DialogTitle id="scroll-dialog-title">
								{props.translations.confirmation.terms_of_use}
								{/* Условия за ползване */}
							</DialogTitle>
							<DialogContent dividers={scroll === 'paper'}>
								<DialogContentText>
									<div dangerouslySetInnerHTML={{
										__html: props.reservation_settings && props.reservation_settings.terms ? props.reservation_settings.terms :
											props.translations.confirmation.you_can_see_general_terms_here + ' https://tabl.bg/terms.pdf'
									}}>
										{/* 'Може да запознаете с общите условия на адрес: https://tabl.bg/terms.pdf' */}
									</div>
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClose} color="primary">
									{/* Затвори */}
									{props.translations.common.close}
								</Button>
							</DialogActions>
						</Dialog>


						<Dialog
							open={openSecond}
							onClose={handleCloseSecond}
							scroll={scrollSecond}
							aria-labelledby="scroll-dialog-title"
						>
							<DialogTitle id="scroll-dialog-title">
								{/* Политика за поверителност */}
								{props.translations.confirmation.privacy_policy}
							</DialogTitle>
							<DialogContent dividers={scrollSecond === 'paper'}>
								<DialogContentText>
									<div
										dangerouslySetInnerHTML={{
											__html: props.reservation_settings && props.reservation_settings.privacy_policy ? props.reservation_settings.privacy_policy :
												props.translations.confirmation.you_can_see_privacy_policy + ' https://tabl.bg/privacy-policy.pdf'
										}}
									>
										{/* // 'Може да запознаете с политиката ни за поверителност на адрес: https://tabl.bg/privacy-policy.pdf' */}
									</div>
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleCloseSecond} color="primary">
									{/* Затвори */}
									{props.translations.common.close}
								</Button>
							</DialogActions>
						</Dialog>


					</div>
				</div>
			</form>

		</React.Fragment>
	);
}