import React, { Component } from "react";
import MyRouter from "./MyRouter";
import { connect } from 'react-redux';
import { SETTINGS_IMG_URL } from './constants';
import './App.css';
// import "bootstrap/dist/css/bootstrap.min.css";

class App extends Component {
  
  componentDidMount() {
    
    document.onreadystatechange = function () {
      var state = document.readyState
      if (state === 'interactive') {
           document.getElementById('root').style.visibility="hidden";
      } else if (state === 'complete') {
        document.getElementById('root').style.visibility="visible";
        var fadeTarget = document.getElementById("load");
        var fadeEffect = setInterval(function () {
            if (!fadeTarget.style.opacity) {
                fadeTarget.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
                fadeTarget.style.opacity -= 0.1;
            } else {
                clearInterval(fadeEffect);
                document.getElementById('load').style.visibility="hidden";
            }
        }, 20);
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    // console.log('nextProps', nextProps)
		if (nextProps.reservation_settings) {
         
      // Set the logo in the sub header
        let logoSrc = nextProps.reservation_settings.logo ? nextProps.reservation_settings.logo : 'https://theschool.tabl.bg/images/the-school-logo2.png';
        let img = document.createElement('img');
        img.src= logoSrc;
        img.classList.add('reservation-restaurant-logo');
        let headerContainer = document.querySelector('header');
        if (headerContainer) {
          headerContainer.appendChild(img);
        }
      

      // Background image
        let bckg = nextProps.reservation_settings.background_image ? nextProps.reservation_settings.background_image : 'https://theschool.tabl.bg/images/the-school-ovcha-kupel-img13.jpg';
        if (bckg.indexOf("http") !== -1) {
          this.backgroundImage = bckg;
        } else {
          this.backgroundImage = SETTINGS_IMG_URL + bckg;
        }
        document.querySelector('.page-container').setAttribute('style', `background-image: url('` + this.backgroundImage + `') !important;`)


      // Favicon logo
        let favIconImg = nextProps.reservation_settings.tabl_short_logo ? nextProps.reservation_settings.tabl_short_logo : 'https://tabl.bg/img/transparent-logo.png';
        if (favIconImg.indexOf("http") !== -1) {
          this.faviconSrc = favIconImg;
        } else {
          this.faviconSrc = SETTINGS_IMG_URL + favIconImg;
        }
        // Set favicon
        let favicon = document.querySelector('link.favicon');
        if (favicon) {
          favicon.setAttribute('href', this.faviconSrc);
        }
    }
  }

  render() {
    return (
        <div className="page-container">
          <MyRouter />
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    reservation_settings: state.reservations.reservation_settings,
  }
};

export default connect(mapStateToProps)(App);