import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import CallUsContainer from './CallUsContainer';
import { RESERVATION_DEFAULT_PHONE_NUM } from '../../constants';

const useStyles = makeStyles(theme => ({
	card: {
		display: 'flex',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		width: 151,
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	playIcon: {
		height: 38,
		width: 38,
	},
}));

export default function MediaControlCard(props) {
	const classes = useStyles();
	//

	const placePhoneNum = props.reservation_settings && props.reservation_settings.default_no_place_phone_num ? props.reservation_settings.default_no_place_phone_num : RESERVATION_DEFAULT_PHONE_NUM; //DEFAULT PHONE NUMBER
	const placePhoneNumNoSpaces = placePhoneNum.replace(' ', '')

	let places = [];
	if (props.places) {
		places = props.places;
		places = Object.keys(places).map(i => places[i]);
	}

	console.log('props.curr_lang',props.curr_lang);
	return (
		<div className="list-places">

			{props.places && (Object.keys(props.places).length > 0) ?

				(places.map(place => (
					<Card className={classes.card + ' each-restaurant-card restaurant-' + place.id + ' ' + ((props.reservationState && props.reservationState.reservationPlace === place.id) ? 'active' : '')} onClick={(e) => props.handleNext('reservationPlace', place.id, place['name' + (props.curr_lang == 'en' ? '_en' : '')])} key={place.id}>
						<div className={classes.details}>
							<CardContent className={classes.content}>
								<Typography component="h5" variant="h5">
									{place['name' + (props.curr_lang == 'en' ? '_en' : '')]}
								</Typography>
								<Typography variant="subtitle1" color="textSecondary">
									{place['address' + (props.curr_lang == 'en' ? '_en' : '')]}

									<br />
									<br />

									<div className="access-time-holder">
										&nbsp;
										<AccessTimeIcon className="access-time-icon" />
										&nbsp;
										{place.start_working_time} - {place.end_working_time}
										&nbsp;
									</div>
								</Typography>
							</CardContent>
						</div>
						<CardMedia
							className={classes.cover}
							image={place.image}
							title={place['name' + (props.curr_lang == 'en' ? '_en' : '')]}
						/>
					</Card>

				)))
				:
				<div className="api-error">
					{(props.places_error && props.places_error.message && Object.values(props.places_error.message).length > 0) ?
						props.places_error.message[props.curr_lang] :
						props.translations.errors.no_restaurants_found
					}
					{/* 'Няма намерени ресторанти, моля свържете се с нас или опитайте по-късно!' */}
					<br />

					<CallUsContainer
						placePhoneNum={placePhoneNum}
						placePhoneNumNoSpaces={placePhoneNumNoSpaces}
					/>
				</div>
			}

			{props.reservationState && props.reservationState.reservationPlace !== 0 ?
				<Button
					variant="contained"
					color="primary"
					onClick={props.handleNext}
					className={' places-next-button'}
				>
					{/* Напред */}
					{props.translations.common.forward}

				</Button>
				: ''}

		</div>

	);
}