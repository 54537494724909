import bgLocale from "date-fns/locale/bg";
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn'
import enLocale from "date-fns/locale/en-GB";
import DateFnsUtils from "@date-io/date-fns";
import React, { useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from '@material-ui/core/Button';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
	RESERVATION_RESERVATION_DURATION,
	RESERVATION_ALLOW_RESERVATIONS_X_DAYS_FROM_NOW,
	RESERVATION_START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY,
	RESERVATION_MAX_RESERVATION_TIME_FOR_DINNER_FOR_TODAY
} from '../../constants';

// Same as - node_modules\date-fns\locale\bg\_lib\localize\index.js
// No translation needed
var dayValues = {
	narrow: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
	short: ['нед', 'пон', 'вто', 'сря', 'чет', 'пет', 'съб'],
	wide: ['неделя', 'понеделник', 'вторник', 'сряда', 'четвъртък', 'петък', 'събота']
};

// Changing day names
bgLocale.localize.day = buildLocalizeFn({
	values: dayValues,
	defaultWidth: 'short',
});

const localeMap = {
	bg: bgLocale,
	en: enLocale,
};

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#cab541',
		},
	},
});


function ListDateTimePicker(props) {
	// const [locale, setLocale] = useState("bg");

	// Constants -------------------------	
	const RESERVATION_DURATION = props.reservation_settings && props.reservation_settings.RESERVATION_DURATION ? parseInt(props.reservation_settings.RESERVATION_DURATION) : parseInt(RESERVATION_RESERVATION_DURATION); // Describes how hours before the place is getting closed, you can book a table. Exmaple: If now is 20:00 and the restaurant works untill 23 - you cant book for today
	const ALLOW_RESERVATIONS_X_DAYS_FROM_NOW = props.reservation_settings && props.reservation_settings.ALLOW_RESERVATIONS_X_DAYS_FROM_NOW ? parseInt(props.reservation_settings.ALLOW_RESERVATIONS_X_DAYS_FROM_NOW) : parseInt(RESERVATION_ALLOW_RESERVATIONS_X_DAYS_FROM_NOW); // Describes how days from now you can book a table
	const START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY = props.reservation_settings && props.reservation_settings.START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY ? parseInt(props.reservation_settings.START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY) : parseInt(RESERVATION_START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY); // Describes the start reservation hour that can be selected, only for today  
	const MAX_RESERVATION_TIME_FOR_DINNER_FOR_TODAY = props.reservation_settings && props.reservation_settings.MAX_RESERVATION_TIME_FOR_DINNER_FOR_TODAY ? parseInt(props.reservation_settings.MAX_RESERVATION_TIME_FOR_DINNER_FOR_TODAY) : parseInt(RESERVATION_MAX_RESERVATION_TIME_FOR_DINNER_FOR_TODAY); // Describes until when you can book for the current day
	// Constants -------------------------

	// Place variables -------------------
	const currentPlace = props.places[props.reservationState.reservationPlace];
	let placeStartWorkTime = parseInt(currentPlace.start_working_time.split(':')[0]);
	let placeEndWorkTime = parseInt(currentPlace.end_working_time.split(':')[0]);
	// Place variables -------------------

	// DatePicker variables --------------
	const todayDate = new Date();
	let minReservationDate = new Date();
	let maxReservationDate = new Date();
	// DatePicker variables --------------


	// Today only check
	if (minReservationDate.getHours() + RESERVATION_DURATION > parseInt(placeEndWorkTime)) { //Now is too late and the place will close soon
		minReservationDate.setDate(minReservationDate.getDate() + 1); // Set minReservationDate to the next day
	}

	// Today only check
	if (((minReservationDate.getHours() >= MAX_RESERVATION_TIME_FOR_DINNER_FOR_TODAY) || (START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY + RESERVATION_DURATION >= placeEndWorkTime)) //Check if is late for dinner or place end worktime is earlier from the dinner + reservation duration => You can book for today
		&& compareDayMonthYear(minReservationDate, todayDate)) {
		minReservationDate.setDate(minReservationDate.getDate() + 1); // Set minReservationDate to the next day
	}

	// Set max reservation date
	maxReservationDate.setDate(maxReservationDate.getDate() + ALLOW_RESERVATIONS_X_DAYS_FROM_NOW);

	// Set select value from the state (if is set)
	let selectedDate = (props.reservationState && props.reservationState.reservationDate) ? props.reservationState.reservationDate : minReservationDate;

	// If current date is forbidden, set the selectDate to the next allowed day (Also prevents auto change selected date)
	while (disableForbiddenDates(selectedDate) === true) {
		let tempDate = selectedDate;
		tempDate.setDate(tempDate.getDate() + 1);

		minReservationDate = tempDate;
		selectedDate = tempDate;
	}

	// Functions
	function disableForbiddenDates(date) {
		// Disable if forbidden
		if (currentPlace.forbidden_reservations) {
			for (let i = 0; i < Object.keys(currentPlace.forbidden_reservations).length; i++) {
				let forbiddenDates = currentPlace.forbidden_reservations[i];
				if (parseInt(forbiddenDates.only_by_phone) === 1) {
					continue;
				}
				let forbidenStartDate = new Date(parseInt(forbiddenDates.forbidden_start_time * 1000))
				let forbidenEndDate = new Date(parseInt(forbiddenDates.forbidden_end_time * 1000));

				let temp_forbidenStartDate = new Date(parseInt(forbidenStartDate.getFullYear()) + '-' + parseInt(forbidenStartDate.getMonth() + 1) + '-' + parseInt(forbidenStartDate.getDate()) + ' 00:00:00');
				let temp_forbidenEndDate = new Date(parseInt(forbidenEndDate.getFullYear()) + '-' + parseInt(forbidenEndDate.getMonth() + 1) + '-' + parseInt(forbidenEndDate.getDate()) + ' 00:00:00');
				let temp_date = new Date(parseInt(date.getFullYear()) + '-' + parseInt(date.getMonth() + 1) + '-' + parseInt(date.getDate()) + ' 00:00:00');

				// If date is forbidden
				if (parseInt(temp_forbidenStartDate.getTime()) <= parseInt(temp_date.getTime()) && parseInt(temp_date.getTime()) <= parseInt(temp_forbidenEndDate.getTime())) {
					return true; //Disable booking for that day
				}
			}
		}

		// Disable if no free tables for that day
		if (props.busy_dates && props.busy_dates.records) {
			let currDayDate = new Date(date);
			currDayDate = currDayDate.getFullYear() + '-' + parseInt(parseInt(currDayDate.getMonth()) + 1) + '-' + currDayDate.getDate();
			currDayDate = new Date(currDayDate);
			currDayDate = currDayDate.getTime();

			let busyDates = Object.entries(props.busy_dates.records);
			for (let i = 0; i < Object.entries(props.busy_dates.records).length; i++) {
				let eachBusyDate = new Date(busyDates[i][0]);
				eachBusyDate = eachBusyDate.getFullYear() + '-' + parseInt(parseInt(eachBusyDate.getMonth()) + 1) + '-' + eachBusyDate.getDate();
				eachBusyDate = new Date(eachBusyDate);
				eachBusyDate = eachBusyDate.getTime();
				if ((parseInt(eachBusyDate) === parseInt(currDayDate)) && (busyDates[i][1].busy === true)) {
					return true; //Disable booking for that day
				}
			}
		}

		return false;
	}

	function customHandleNext(date) {
		props.handleNext('reservationDate', date, appendLeadingZeroes(date.getDate()) + '/' + appendLeadingZeroes(date.getMonth() + 1) + '/' + date.getFullYear())
	}

	function appendLeadingZeroes(n) {
		if (n <= 9) {
			return "0" + n;
		}
		return n
	}

	function compareDayMonthYear(dateOne, dateTwo) {
		if (dateOne.getDate() === dateTwo.getDate()
			&& dateOne.getMonth() === dateTwo.getMonth()
			&& dateOne.getFullYear() === dateTwo.getFullYear()) {
			return true;
		}
		return false;
	}

	let dPicker;
	if (window.innerWidth <= 500) {
		dPicker = <ThemeProvider theme={theme}>
			<DatePicker
				autoOk
				variant="inline"
				value={selectedDate}
				onChange={customHandleNext}
				minDate={minReservationDate}
				maxDate={maxReservationDate}
				hintText="reservation calendar"
				shouldDisableDate={disableForbiddenDates}
				format="dd/MM/yyyy"
				className="p20 bradius-top-5 date-picker-calendar date-picker-modal"
				openTo="date"
				theme={theme}
			/>
		</ThemeProvider>
	} else {
		dPicker = <DatePicker
			theme={theme}
			autoOk
			value={selectedDate}
			onChange={customHandleNext}
			minDate={minReservationDate}
			maxDate={maxReservationDate}
			hintText="reservation calendar"
			shouldDisableDate={disableForbiddenDates}
			format="dd/MM/yyyy"
			variant="static"
			openTo="date"
			className="date-picker-calendar"
		/>
	}

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[props.curr_lang]}>

			<div className="calendar-holder">
				{dPicker}

				<Button
					variant="contained"
					onClick={(e) => customHandleNext(selectedDate)}
					className={'date-picker-next-button mtop15'}
				>
					{/* Напред */}
					{props.translations.common.forward}
				</Button>
			</div>

		</MuiPickersUtilsProvider>
	);
}

export default ListDateTimePicker;
