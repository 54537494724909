// import MultiLanguage from "react-redux-multilang";
import { enTranslations } from './translations/en';
import { bgTranslations } from './translations/bg';
export const translations =
//new MultiLanguage(
{
    en: enTranslations,
    bg: bgTranslations,
}
// )

