export const bgTranslations = {
    steps: {
        restaurant: 'Ресторант',
        date: 'Дата',
        time: 'Час',
        count_people: 'Брой хора',
        room: 'Зала',
        confirmation: 'Потвърждение',
        confirmation_done: 'Потвърдена резервация',
    },
    timePicker: {
        reservations_about: 'Резервации за',
        are_accepted_only_by_phone: 'се приемат само по телефона:',

        hour: 'Час',
        minutes: 'Минути',

        for_reservations_before: 'За резервация преди',
        today_call_us_via_phone: ':00 часа днес, моля свържете се с нас на телефон:',
    },
    countPeople: {
        for_reservations_about_more_than: 'За резервация за повече от',
        people_please_contact_us_via_phone: 'човека, моля свържете се с нас по телефона.',
        count_people: 'Брой Хора',
        more_than: 'Повече от',
    },
    rooms: {
        for_smokers: 'За пушачи',
        open_space: 'Закрито помещение',
        closed_space: 'Открито помещение',
        no_rooms_found_according_to_criterias_above: 'Няма намерени зали за резервация, моля свържете се с нас или променете избраните критерии по-горе!'
    },
    confirmation: {
        booking_unsuccessful: 'Резервацията беше неуспешна. Моля опитайте по-късно или се свържете с нас!',
        please_enter_your_details_correctly: 'Моля въведете вашите данни коректно и се уверете че сте се съгласили с условията',
        first_and_last_name: 'Име и Фамилия',
        phone: 'Тел. номер',
        read_and_agree_with: 'Запознах се и съм съгласен с',
        the_privacy_policies: 'Политиките за поверителност',
        agree_with: 'Съгласен съм с',
        the_terms_of_use: 'Условията за ползване',
        have_16_years: 'и имам навършени 16 години',
        remember_my_credentials: 'Запомни данните ми при следващо влизане',
        make_a_reservation: 'Резервирай',
        terms_of_use: 'Условия за ползване',
        you_can_see_general_terms_here: 'Може да запознаете с общите условия на адрес:',
        privacy_policy: 'Политика за поверителност',
        you_can_see_privacy_policy: 'Може да запознаете с политиката ни за поверителност на адрес:',
    },
    finalStep: {
        booking_sucessful: 'РЕЗЕРВАЦИЯТА Е НАПРАВЕНА УСПЕШНО',
        your_table_will_be_kept_in_15_mins: `Не забравяйте, Масата Ви ще се пази <span class="bold">не повече от 15 минути.</span>`,
        if_you_are_late_reservation_may_be_cancelled: 'При закъснение The School си запазва правото да откаже резервацията!',
        your_reservation: 'Вашата резервация',
        navigation: 'Как да стигна',

        cancel_booking: 'Откажи резервацията',

        are_you_sure_you_want_to_cancel: 'Сигурни ли сте, че искате да анулирате току-що направената резервация ?',
        cancel_booking_text: 'АНУЛИРАЙ РЕЗЕРВАЦИЯТА',
        booking_cancelled_sucessfully: 'Резервацията е отказана успешно!'
    },
    common: {
        close: 'Затвори',
        forward: 'Напред',
        back: 'Назад',
        digital_solutions_for_your_business: 'Дигитални решения за Вашия бизнес!',
    },
    errors: {
        no_restaurants_found: 'Няма намерени ресторанти, моля свържете се с нас или опитайте по-късно!'
    }

}