export const enTranslations = {
    steps: {
        restaurant: 'Restaurant',
        date: 'Date',
        time: 'Time',
        count_people: 'Count people',
        room: 'Room',
        confirmation: 'Confirmation',
        confirmation_done: 'Reservation confirmed',
    },
    timePicker: {
        reservations_about: 'Reservations for',
        are_accepted_only_by_phone: 'are only accepted by phone',

        hour: 'Hour',
        minutes: 'Minutes',

        for_reservations_before: 'To book before',
        today_call_us_via_phone: ':00 today, please contact us by phone:',
    },
    countPeople: {
        for_reservations_about_more_than: 'For reservations about more than',
        people_please_contact_us_via_phone: 'people, please contact us by phone.',
        count_people: 'Count People',
        more_than: 'More than',
    },
    rooms: {
        for_smokers: 'For smokers',
        open_space: 'Open space',
        closed_space: 'Closed space',
        no_rooms_found_according_to_criterias_above: 'No booking rooms found, please contact us or change the selected criteria above!'
    },
    confirmation: {
        booking_unsuccessful: 'The reservation was not successful, try again later or contact us!',
        please_enter_your_details_correctly: 'Please enter your details correctly and make sure you agree to the terms',
        first_and_last_name: 'First and Last Name',
        phone: 'Phone number',
        read_and_agree_with: 'I have read and agree with',
        the_privacy_policies: 'Privacy Policies',
        agree_with: 'I agree with',
        the_terms_of_use: 'Terms of Use',
        have_16_years: 'and I am at least 16 years old',
        remember_my_credentials: 'Remember my details next time you log in',
        make_a_reservation: 'Make a reservation',
        terms_of_use: 'Terms of Use',
        you_can_see_general_terms_here: 'You can check the terms and conditions at:',
        privacy_policy: 'Privacy Policy',
        you_can_see_privacy_policy: 'You can check the privacy policy at:',
    },
    finalStep: {
        booking_sucessful: 'RESERVATION SUCCESSFULLY MADE',
        your_table_will_be_kept_in_15_mins: `Remember, your table will be kept <span class="bold">no more than 15 minutes.</span>`,
        if_you_are_late_reservation_may_be_cancelled: 'In case of delay, The School reserves its the right to refuse the reservation!',
        your_reservation: 'Your reservation',
        navigation: 'How to get there',

        cancel_booking: 'Cancel the reservation',

        are_you_sure_you_want_to_cancel: 'Are you sure you want to cancel the reservation you just made?',
        cancel_booking_text: 'CANCEL RESERVATION',
        booking_cancelled_sucessfully: 'The reservation was successfully canceled!'
    },
    common: {
        close: 'Close',
        forward: 'Forward',
        back: 'Go back',
        digital_solutions_for_your_business: 'Digital solutions for your business!',
    },
    errors: {
        no_restaurants_found: 'No found restaurants, please contact us or try again later!'
    }

}