import 'raf/polyfill';
import browserSupport from './browsersSupport';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { BrowserRouter } from 'react-router-dom'

import App from './App';
import store from './store';
import { listPlaces, getReservationsSettings } from './actions/reservations.action';

store.dispatch(listPlaces());
store.dispatch(getReservationsSettings());

const app = 
<Provider store={store}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
</Provider>

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
