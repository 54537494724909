import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CallUsContainer from './CallUsContainer';
import {
	RESERVATION_ALLOW_RESERVATIONS_X_HOURS_BEFORE_PLACE_IS_CLOSED,
	RESERVATION_START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY
} from '../../constants';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

export default function SimpleSelect(props) {
	const classes = useStyles();

	function appendLeadingZeroes(n) {
		if (n <= 9) {
			return "0" + n;
		}
		return n
	}

	// Constants -------------------------
	const ALLOW_RESERVATIONS_X_HOURS_BEFORE_PLACE_IS_CLOSED = props.reservation_settings && props.reservation_settings.ALLOW_RESERVATIONS_X_HOURS_BEFORE_PLACE_IS_CLOSED ? parseInt(props.reservation_settings.ALLOW_RESERVATIONS_X_HOURS_BEFORE_PLACE_IS_CLOSED) : parseInt(RESERVATION_ALLOW_RESERVATIONS_X_HOURS_BEFORE_PLACE_IS_CLOSED); // Describes how is the reservation duration and how hours before the place is getting closed, you can book a table.
	const START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY = props.reservation_settings && props.reservation_settings.START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY ? parseInt(props.reservation_settings.START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY) : parseInt(RESERVATION_START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY); // Describes the start reservation hour that can be selected, only for today  
	// Constants -------------------------

	// Place variables -------------------
	const currentPlace = props.places[props.reservationState.reservationPlace];
	let placeStartWorkTime = parseInt(currentPlace.start_working_time.split(':')[0]);
	let placeEndWorkTime = parseInt(currentPlace.end_working_time.split(':')[0]);
	// Place variables -------------------

	let todayDate = new Date();

	// Date variables -------------------
	const reservationDate = props.reservationState.reservationDate;
	// Date variables -------------------

	let [errors, setErrors] = React.useState({
		booking_for_today: compareDayMonthYear(todayDate, reservationDate),
		cantBookForTodayNoPlaces: false,
	})

	let cantBookForTodayNoPlaces = false;
	let forbiddenHours = [];
	let forbiddenMinutes = [];

	let showResDate = appendLeadingZeroes(reservationDate.getDate()) + '.' + appendLeadingZeroes(parseInt(parseInt(reservationDate.getMonth()) + 1)) + '.' + reservationDate.getFullYear() + (props.curr_lang == 'en' ? 'y.' : 'г.');

	let resTimeStamp = (new Date(reservationDate)).getTime() / 1000;
	for (let i = 0; i < currentPlace.forbidden_reservations.length; i++) {
		let forbiddenDates = currentPlace.forbidden_reservations[i];
		if (parseInt(forbiddenDates.forbidden_start_time) <= resTimeStamp && resTimeStamp <= parseInt(forbiddenDates.forbidden_end_time)) {
			cantBookForTodayNoPlaces = true;
		}
	}

	// Arr with busy dates
	if (props.busy_dates && props.busy_dates.records) {
		let resDate = reservationDate.getFullYear() + '-' + appendLeadingZeroes(parseInt(parseInt(reservationDate.getMonth()) + 1)) + '-' + appendLeadingZeroes(reservationDate.getDate());
		if (props.busy_dates.records[resDate]) {

			if (props.busy_dates.records[resDate] && props.busy_dates.records[resDate].hours) {
				let forbH = props.busy_dates.records[resDate].hours;

				forbH = Object.values(forbH);
				for (let i = 0; i < forbH.length; i++) {
					let ForbHSplit = forbH[i].split(':');
					let eachForbH = parseInt(ForbHSplit[0]);

					let eachForbMin = parseInt(ForbHSplit[1]);
					if (typeof forbiddenMinutes[eachForbH] !== "object") {
						forbiddenMinutes[eachForbH] = []
					}
					forbiddenMinutes[eachForbH].push(eachForbMin);

					if ((!forbiddenHours.includes(eachForbH))
						&& forbH.includes(appendLeadingZeroes(eachForbH) + ':00')
						&& forbH.includes(appendLeadingZeroes(eachForbH) + ':15')
						&& forbH.includes(appendLeadingZeroes(eachForbH) + ':30')
						&& forbH.includes(appendLeadingZeroes(eachForbH) + ':45')
					) {
						forbiddenHours.push(eachForbH);
					}
				}
			}

		}
	}

	let startBookingHour = calculateStartBookingHour(reservationDate, placeStartWorkTime);
	let endBookingHour = placeEndWorkTime - ALLOW_RESERVATIONS_X_HOURS_BEFORE_PLACE_IS_CLOSED;

	function calculateStartBookingHour(reservationDate, placeStartWorkTime) {
		let currentHour = todayDate.getHours();
		let startBHour = placeStartWorkTime;

		if (compareDayMonthYear(reservationDate, todayDate) && startBHour < START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY) {
			startBHour = Math.max(currentHour, START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY)
		}

		let useForbAsStartHour = false;
		let foundFirstStartForbHour = false;
		let newStartBHour;
		let mustGetMax = true;
		forbiddenHours.map((frb, i) => {
			if (frb === startBHour) {
				useForbAsStartHour = true;
			}

			if (typeof forbiddenHours[i + 1] !== "undefined" && foundFirstStartForbHour === false) {
				if (forbiddenHours[i + 1] - frb !== 1) {
					mustGetMax = false;
					foundFirstStartForbHour = true;
					newStartBHour = frb + 1;
				}
			}
		})

		if (mustGetMax) {
			newStartBHour = Math.max(...forbiddenHours) + 1
		}

		if (useForbAsStartHour && newStartBHour !== startBHour) {
			startBHour = newStartBHour;
		}
		return startBHour;
	}

	let useHour = parseInt(startBookingHour);
	let startBookingMinute = 0;
	let endBookingMinute = 45;


	let useMinutes = parseInt(startBookingMinute);
	if (props.reservationState && props.reservationState.reservationTime) {
		let resTime = props.reservationState.reservationTime.split(':');
		useHour = parseInt(resTime[0]);
		useMinutes = parseInt(resTime[1]);
	}

	const [values, setValues] = React.useState({
		hour: useHour,
		minutes: useMinutes,
		// availableHours: generateHours(startBookingHour, endBookingHour),
		// availableMinutes: generateMinutes(startBookingMinute, endBookingMinute)
	});

	if (typeof values.availableHours === "undefined") {
		setValues(oldValues => ({
			...oldValues,
			['availableHours']: generateHours(startBookingHour, endBookingHour),
		}));
	}

	function handleChange(event) {
		setValues(oldValues => ({
			...oldValues,
			[event.target.name]: event.target.value,
		}));

		if (event.target.name === 'hour') {
			setValues(oldValues => ({
				...oldValues,
				['availableMinutes']: generateMinutes(startBookingMinute, endBookingMinute, event.target.value),
			}));
		}

		if (event.target.name === 'minutes') {
			props.handleNext('reservationTime', parseInt(values.hour) + ':' + parseInt(event.target.value), parseInt(values.hour) + ':' + appendLeadingZeroes(parseInt(event.target.value)));
		}
	}

	function submitTime() {
		props.handleNext('reservationTime', parseInt(values.hour) + ':' + parseInt(values.minutes), parseInt(values.hour) + ':' + appendLeadingZeroes(parseInt(values.minutes)));
	}

	function generateHours(startBookingHour, endBookingHour) {
		let h = [];
		let firstHour = startBookingHour;
		let metFirstHour = false;
		for (let i = 0; i <= (parseInt(endBookingHour) - parseInt(startBookingHour)); i++) {
			let showHour = parseInt(startBookingHour) + i;

			if (compareDayMonthYear(reservationDate, todayDate) && showHour < START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY) {
				continue;
			}

			if (!forbiddenHours.includes(showHour)) {
				if (metFirstHour === false) {
					metFirstHour = true;

					if (props.reservationState && props.reservationState.reservationTime) {
						let resTime = props.reservationState.reservationTime.split(':');
						firstHour = parseInt(resTime[0]);
					} else {
						firstHour = showHour;
					}
				}
				h.push(<MenuItem key={'hour' + i} value={showHour}>{showHour}</MenuItem>);
			}
		}

		setValues(oldValues => ({
			...oldValues,
			['hour']: firstHour
		}));

		generateMinutes(startBookingMinute, endBookingMinute, firstHour)

		return h;
	}

	function generateMinutes(startBookingMinute, endBookingMinute, chosenHour) {
		let m = [];
		let flagFirstMin = false;
		let firstMin = startBookingMinute;

		for (let i = 0; i < ((parseInt(endBookingMinute) - parseInt(startBookingMinute)) / 15 + 1); i++) {
			let showMin = parseInt(startBookingMinute) + (i * 15);

			if (forbiddenMinutes[chosenHour]) {
				if (forbiddenMinutes[chosenHour].includes(showMin)) {
					continue;
				}
			}

			if (flagFirstMin === false) {
				if (props.reservationState && props.reservationState.reservationTime) {
					let resTime = props.reservationState.reservationTime.split(':');
					firstMin = parseInt(resTime[1]);
				} else {
					firstMin = showMin;
				}
			}
			flagFirstMin = true;
			m.push(<MenuItem key={'minutes' + i} value={showMin}>{appendLeadingZeroes(showMin)}</MenuItem>);

		}

		setValues(oldValues => ({
			...oldValues,
			['minutes']: firstMin,
			['availableMinutes']: m
		}));

	}


	function appendLeadingZeroes(n) {
		return n <= 9 ? "0" + n : n;
	}

	function compareDayMonthYear(dateOne, dateTwo) {
		if (dateOne.getDate() === dateTwo.getDate()
			&& dateOne.getMonth() === dateTwo.getMonth()
			&& dateOne.getFullYear() === dateTwo.getFullYear()) {
			return true;
		}
		return false;
	}

	const placePhoneNum = (props.places && props.reservationState.reservationPlace && props.places[props.reservationState.reservationPlace] && props.places[props.reservationState.reservationPlace].contact_phone) ? props.places[props.reservationState.reservationPlace].contact_phone : '';
	const placePhoneNumNoSpaces = placePhoneNum.replace(' ', '')

	return (
		<form className={classes.root} autoComplete="off">
			<div className="time-holder">

				{cantBookForTodayNoPlaces === true ?
					<React.Fragment>
						<div className="time-picker-error-text top-border-left-and-right">
							{props.translations.timePicker.reservations_about} {showResDate} {props.translations.timePicker.are_accepted_only_by_phone}
							{/* Резервации за {showResDate} се приемат само по телефона: */}
							<br />
							<CallUsContainer
								placePhoneNum={placePhoneNum}
								placePhoneNumNoSpaces={placePhoneNumNoSpaces}
							/>
						</div>

						<Button
							variant="contained"
							onClick={props.goBack}
							className={'time-picker-next-button mtop15'}
						>
							{/* Назад */}
							{props.translations.common.back}
						</Button>
					</React.Fragment>

					:

					<React.Fragment>

						<div className="time-holder-form">
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="age-simple">{props.translations.timePicker.hour}
									{/* Час */}
								</InputLabel>
								<Select
									value={values.hour}
									onChange={handleChange}
									inputProps={{
										name: 'hour',
										id: 'age-simple',
									}}
								>
									{values.availableHours}
								</Select>
							</FormControl>


							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="age-simple">
									{props.translations.timePicker.minutes}
									{/* Минути */}
								</InputLabel>
								<Select
									value={values.minutes}
									onChange={handleChange}
									inputProps={{
										name: 'minutes',
										id: 'age-simple',
									}}
								>
									{values.availableMinutes}
								</Select>
							</FormControl>
						</div>

						{/* <br/> */}
						<Button
							variant="contained"
							onClick={submitTime}
							className={'time-picker-next-button mtop15'}
						>
							{/* Напред */}
							{props.translations.common.forward}
						</Button>
					</React.Fragment>
				}

				{errors.booking_for_today ?
					<div className="time-picker-error-text">
						{/* За резервация преди  */}
						{/* :00 часа днес, моля свържете се с нас на телефон: */}
						{props.translations.timePicker.for_reservations_before} {START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY}{props.translations.timePicker.today_call_us_via_phone}
						<br />
						<CallUsContainer
							placePhoneNum={placePhoneNum}
							placePhoneNumNoSpaces={placePhoneNumNoSpaces}
						/>
					</div>
					:
					''
				}

			</div>
		</form>
	);
}