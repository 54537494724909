import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';
import SmokeFreeIcon from '@material-ui/icons/SmokeFree';

import CallUsContainer from './CallUsContainer';
import { RESERVATION_DEFAULT_PHONE_NUM } from '../../constants';

const useStyles = makeStyles(theme => ({
	card: {
		display: 'flex',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		width: 151,
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	playIcon: {
		height: 38,
		width: 38,
	},
}));

export default function MediaControlCard(props) {

	const classes = useStyles();
	const theme = useTheme();

	// console.log('reservationState ROOMS', props.reservationState)
	// console.log('props.rooms', props.rooms)
	const placePhoneNum = (props.places && props.reservationState.reservationPlace && props.places[props.reservationState.reservationPlace] && props.places[props.reservationState.reservationPlace].contact_phone) ? props.places[props.reservationState.reservationPlace].contact_phone : RESERVATION_DEFAULT_PHONE_NUM;
	const placePhoneNumNoSpaces = placePhoneNum.replace(' ', '')

	let rooms = [];
	if (props.rooms) {
		rooms = props.rooms;
		rooms = Object.keys(rooms).map(i => rooms[i]);
	}

	return (
		<div className="list-rooms">

			{/* 
				<Button
				className="room-doesnt-matter"
				variant="contained"
				color="primary"
				// onClick={handleNext}
				onClick={(e) => props.handleNext('reservationRoom', -1, 'Без значение')}
				// // className={classesSteppers.button}
				>
				Без значение
				</Button> 
			*/}

			{props.rooms && (Object.keys(props.rooms).length > 0) ?

				<React.Fragment>

					{rooms.map(room => (
						<Card key={room.id} className={classes.card + ' each-room-card ' + ((props.reservationState && props.reservationState.reservationRoom === room.id) ? 'active' : '')} onClick={(e) => props.handleNext('reservationRoom', room.id, room['name' + (props.curr_lang == 'en' ? '_en' : '')])}>
							<div className={classes.details}>
								<CardContent className={classes.content}>
									<Typography component="h5" variant="h5">
										{room['name' + (props.curr_lang == 'en' ? '_en' : '')]}
									</Typography>
									<Typography variant="subtitle1" color="textSecondary">
										{props.places[room.place_id]['name' + (props.curr_lang == 'en' ? '_en' : '')]}
										<br />
										<br />
										{room.smoking === '1' ?
											<React.Fragment>
												<SmokingRoomsIcon className="smoking-icon" />
												{/* За пушачи */}
												{props.translations.rooms.for_smokers}
											</React.Fragment>
											:
											<React.Fragment>
												<SmokeFreeIcon className="smoking-icon" />
												{/* 'Закрито помещение' : 'Открито помещение'} */}
												{room.is_in === '1' ?
													props.translations.rooms.closed_space : props.translations.rooms.open_space
												}
											</React.Fragment>
										}

									</Typography>
								</CardContent>
								{/* <div className={classes.controls}>
							<IconButton aria-label="previous">
								{theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
							</IconButton>
							<IconButton aria-label="play/pause">
								<PlayArrowIcon className={classes.playIcon} />
							</IconButton>
							<IconButton aria-label="next">
								{theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
							</IconButton>
							</div> */}
							</div>
							<CardMedia
								className={classes.cover}
								image={room.image}
								title={props.places[room.place_id].name + ', ' + room['name' + (props.curr_lang == 'en' ? '_en' : '')]}
							/>
						</Card>
					))}

					{props.reservationState && props.reservationState.reservationRoom !== 0 && Object.keys(props.rooms).includes(String(props.reservationState.reservationRoom)) ?
						<Button
							variant="contained"
							color="primary"
							onClick={props.handleNext}
							className={' rooms-next-button'}
						>
							{/* Напред */}
							{props.translations.common.forward}
						</Button>
						:
						''
					}
				</React.Fragment>
				:
				<React.Fragment>

					<div className="api-error">
						{(props.rooms_error && props.rooms_error.message && Object.values(props.rooms_error.message).length > 0) ?
							props.rooms_error.message[props.curr_lang] :
							props.translations.rooms.no_rooms_found_according_to_criterias_above
						}
						{/* 'Няма намерени зали за резервация, моля свържете се с нас или променете избраните критерии по-горе!' */}
						<br />
						<CallUsContainer
							placePhoneNum={placePhoneNum}
							placePhoneNumNoSpaces={placePhoneNumNoSpaces}
						/>
					</div>

				</React.Fragment>
			}

		</div>

	);
}